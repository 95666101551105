import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearStorage } from "library/utilities/Storage";
import store from "main/store";
import { setAuth } from "library/common/actions/AuthActions";
import { Footer } from "flowbite-react";
import {
  BsDribbble,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsTwitter,
  BsXLg,
} from "react-icons/bs";

export default function FooterComponent() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [authPages, setAuthPages] = React.useState(false);
  const [user, setUser] = React.useState({ username: "" });
  const userData = useSelector(state => state.authReducer);

  let location = useLocation();
  React.useEffect(() => {
    if (userData.isLoggedIn) {
      setUser(userData.user);
    }
    if (location.pathname !== "/") {
      setAuthPages(true);
    }
  }, [location]);

  const menuItems = [
    "Profile",
    "Dashboard",
    "Activity",
    "Analytics",
    "System",
    "Deployments",
    "My Settings",
    "Team Settings",
    "Help & Feedback",
    "Log Out",
  ];

  const logoutHandler = () => {
    clearStorage();
    store.dispatch(
      setAuth({
        user: null,
        token: null,
      }),
    );
    window.location.reload();
  };

  return (
    <div className="">
      <Footer container>
        <div className="w-full mt-10">
          <div className="grid w-full justify-between sm:flex sm:justify-between self-center md:flex md:grid-cols-1">
            <div className="flex  justify-center align-middle">
              <img src="/logo.svg" width={120} />

              <p className=" break-words text-xs w-[300px] mt-5 ml-2">
                Lorem ipsum dolor sit amet consectetur. Augue sit blandit lacus
                cras id odio mattis et magna. At et pellentesque pharetra est
                odio. Felis sapien sagittis semper sem morbi nunc semper.
                Malesuada vestibulum a massa ut...
              </p>
            </div>
            <div className="grid  grid-cols-4 gap-8 sm:mt-4 sm:grid-cols-5 sm:gap-3">
              <div>
                <Footer.LinkGroup col>
                  <Footer.Link href="#">Categories</Footer.Link>
                  <Footer.Link href="#">Privacy Policy</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.LinkGroup col>
                  <Footer.Link href="#">Blogs</Footer.Link>
                  <Footer.Link href="#">Terms of use</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.LinkGroup col>
                  <Footer.Link href="#">About</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.LinkGroup col>
                  <Footer.Link href="/contactus">Contact Us</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.LinkGroup col>
                  <Footer.Link href="#">Post A deal</Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
              <div>
                <Footer.Title title="Social Media" />
                <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                  <Footer.Icon href="#" icon={BsFacebook} />
                  <Footer.Icon href="#" icon={BsInstagram} />
                  <Footer.Icon href="#" icon={BsTwitter} />
                </div>
              </div>
            </div>
          </div>
          <Footer.Divider />
          {/* <div className="w-full sm:flex sm:items-center sm:justify-between">
            <Footer.Copyright href="#" by="Flowbite™" year={2022} />
            <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
              <Footer.Icon href="#" icon={BsFacebook} />
              <Footer.Icon href="#" icon={BsInstagram} />
              <Footer.Icon href="#" icon={BsTwitter} />
              <Footer.Icon href="#" icon={BsGithub} />
              <Footer.Icon href="#" icon={BsDribbble} />
            </div>
          </div> */}
        </div>
      </Footer>
    </div>
  );
}
