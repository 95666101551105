import React from "react";
import { Card, CardBody, Image, Button, Slider } from "@nextui-org/react";

export default function App({ image, title, description, buttonTitle }) {
  const [liked, setLiked] = React.useState(false);

  return (
    <div className=" flex mt-5 justify-center align-middle w-full ">
      <Card className="border-none shadow-none bg-secondaryGray  w-[95%]   ">
        <CardBody>
          <div className="grid  md:flex md:justify-between grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 ">
            <div className="flex flex-col justify-center col-span-6 md:col-span-8">
              <div className="flex flex-col justify-between items-start">
                <div className="flex flex-col gap-0">
                  <h1 className="text-large font-medium mt-2">{title}</h1>

                  <p className="text-small mt-5 text-foreground/80 break-inside-auto md:w-[500px]">
                    {description}
                  </p>
                </div>
                {buttonTitle && (
                  <Button
                    //   isIconOnly
                    className="text-white data-[hover]:bg-foreground/10 hover:text-gray-800 mt-6 bg-primaryOrange "
                    radius="sm"
                    variant="solid"
                    onPress={() => setLiked(v => !v)}
                  >
                    {buttonTitle}
                  </Button>
                )}
              </div>
            </div>
            <div className=" col-span-6 md:col-span-5">
              <Image
                alt="Album cover"
                className="object-cover"
                src={image}
                width="100%"
                // height={1500}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
