import Axios from "axios";
import { fetchFromStorage } from "library/utilities/Storage";
import { cloneDeep } from "lodash";

const axiosInstance = Axios.create({
  baseURL: "https://admin.naardeals.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async config => {
  const clonedConfig = cloneDeep(config);
  const token = fetchFromStorage("access-token");

  clonedConfig.headers.common = {
    Authorization: `Bearer ${token}`,
  };

  return clonedConfig;
});

export default axiosInstance;
