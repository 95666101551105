import React, { useEffect, useState } from "react";
import CardComponent from "../../Components/Card";
import CategoriesJumbotron from "../../resources/images/Frame 427318918.svg";
import { Button, ButtonGroup, Chip, Input } from "@nextui-org/react";
import SortIcon from "../../resources/images/sort 1.svg";
import HomeAndFurnitureIcon from "../../resources/images/Homeandfurniture.svg";
import ElectronicsIcon from "../../resources/images/electronics.svg";
import FireIcon from "../../resources/images/FireIcon.svg";
import ProductImage from "../../resources/images/ProductImage.png";
import SmallFireIcon from "../../resources/images/SmallFireIcon.svg";
import DislikeIcon from "../../resources/images/Thumbs down.svg";
import SaveIcon from "../../resources/images/save.svg";
import SaleIcon from "../../resources/images/SaleIcon.svg";
import VerifyIcon from "../../resources/images/verifiyIcon.svg";
import HourGlassIcon from "../../resources/images/hourglass-end.svg";
import CommentIcon from "../../resources/images/comment-dots.svg";
import HeadingTitle from "Components/HeadingTitle";
import MainCard from "../../Components/MainCard";
import { Tabs, Tab } from "@nextui-org/react";
import FlatList from "flatlist-react";
import { Pagination } from "@nextui-org/react";

import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [deals, setDeals] = useState([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(0);
  const [offset, setOffset] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const location = useLocation();

  const getDataRefresh = () => {
    axios.get("/categories").then(response => {
      setCategories(response.data.categories);
    });
    dealsHandler();
  };
  const dealsHandler = () => {
    if (selected == 0) {
      axios.get(`/deals?page=${offset}`).then(response => {
        console.log(response.data.meta);
        setDeals(response.data.deals);
        setTotalPerPage(response.data.meta.total_pages);
      });
    } else if (selected > 0) {
      axios.get(`/categories/${selected}`).then(response => {
        // console.log(response.data);
        setDeals(response.data.category?.deals);
      });
    }
  };
  // const loadMoreHandler = () => {
  //   setHasMoreItems(true);
  //   axios.get(`/deals?page=${offset + 1}`).then(response => {
  //     console.log(response.data);
  //     setOffset(offset + 1);
  //     setDeals(deals.push(response.data.deals));
  //     setHasMoreItems(false);
  //   });
  // };
  useEffect(() => {
    const getCategoriesHandler = () => {
      axios.get("/categories").then(response => {
        setCategories(response.data.categories);
      });
    };

    getCategoriesHandler();
    dealsHandler();
  }, [location]);

  const onCategoryClickHandler = id => {
    if (id > 0) {
      axios.get(`/categories/${id}`).then(response => {
        setDeals(response.data.category?.deals);
      });
    } else {
      axios.get(`/deals`).then(response => {
        // console.log(response.data);
        setDeals(response.data.deals);
      });
    }
  };

  const renderDeal = (d, idx) => {
    return (
      <MainCard
        image={d.images[0] ? d.images[0] : ProductImage}
        images={d.images}
        id={d.id}
        refresh={getDataRefresh}
        key={idx}
        likeIcon={SmallFireIcon}
        likes={d.likes}
        isLiked={d.isLikedBy}
        DislikeIcon={DislikeIcon}
        productTitle={d.name}
        productDescription={d.desc}
        saveIcon={SaveIcon}
        categorModal={d.category.name}
        saleIcon={SaleIcon}
        userName={"Floyd Miles"}
        VerifyIcon={VerifyIcon}
        categoryName={d.deal_merchant}
        HourGlassIcon={HourGlassIcon}
        dueDate={"Ends in 2 Days"}
        numberOfComments={20}
        commentIcon={CommentIcon}
        discount={d.discount * 100 + "%"}
        coupon={d.coupon}
        url={d.url}
        favourited={d.favourited}
      />
    );
  };

  const loadAnotherPage = off => {
    axios.get(`/deals?page=${off}`).then(response => {
      // console.log(response.data.meta);
      setDeals(response.data.deals);
      setOffset(off);
      setTotalPerPage(response.data.meta.total_pages);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
  };

  return (
    <>
      <CardComponent
        image={CategoriesJumbotron}
        title={"Catch your Perfect Deal"}
        description={
          "Save up to 60% with our daily offers, Check our category page for all discounts and broachers for all categories you need."
        }
      />

      <div className="container mx-auto mt-5 ">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <ButtonGroup>
              <Input
                classNames={{
                  base: "max-w-full sm:max-w-[20rem] h-10 rounded-r-none",
                  mainWrapper: "h-full rounded-r-none",
                  input: "text-small rounded-r-none",
                  inputWrapper:
                    "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20 rounded-r-none",
                }}
                placeholder="Type to search..."
                onChange={e => {
                  setSearch(e.target.value);
                }}
                size="sm"
                variant="bordered"
                type="search"
              />

              <Button>Search</Button>
            </ButtonGroup>
          </div>
          <div className="">
            <Chip
              classNames={{
                base: "bg-transparent",
                content: "drop-shadow shadow-black text-black",
              }}
              avatar={<img src={SortIcon} />}
            >
              Sort
            </Chip>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-5">
        <Tabs
          aria-label="Options"
          fullWidth
          selectedKey={selected}
          radius="full"
          size="lg"
          onSelectionChange={s => {
            console.log(s);
            setSelected(s);
            onCategoryClickHandler(s);
          }}
        >
          <Tab
            key={0}
            title={
              <div className="flex items-center space-x-2">
                <span>All Categories</span>
              </div>
            }
          >
            <FlatList
              list={deals}
              renderItem={renderDeal}
              renderOnScroll={true}
              search={{
                term: search,
                by: [
                  { key: "name", caseInsensitive: true },
                  { key: "desc", caseInsensitive: true },
                ],
              }}
              wrapperHtmlTag="div"
              hasMoreItems={hasMoreItems}
              renderWhenEmpty={() => <div>List is empty!</div>}
              sortBy={["name", { key: "name", descending: true }]}
            />
            <div className="mt-4">
              <Pagination
                total={totalPerPage}
                initialPage={1}
                // showControls
                color="warning"
                showShadow
                isCompact
                // page={offset}
                onChange={loadAnotherPage}
              />
            </div>

            {/* {deals.map((d, i) => {
              return (
                <MainCard
                  image={d.images[0] ? d.images[0] : ProductImage}
                  images={d.images}
                  id={d.id}
                  refresh={getDataRefresh}
                  key={d.id}
                  likeIcon={SmallFireIcon}
                  likes={d.likes}
                  isLiked={d.isLikedBy}
                  DislikeIcon={DislikeIcon}
                  productTitle={d.name}
                  productDescription={d.desc}
                  saveIcon={SaveIcon}
                  categorModal={d.category.name}
                  saleIcon={SaleIcon}
                  userName={"Floyd Miles"}
                  VerifyIcon={VerifyIcon}
                  categoryName={d.deal_merchant}
                  HourGlassIcon={HourGlassIcon}
                  dueDate={"Ends in 2 Days"}
                  numberOfComments={d.no_of_comments}
                  commentIcon={CommentIcon}
                  discount={d.discount * 100 + "%"}
                  coupon={d.coupon}
                  url={d.url}
                />
              );
            })} */}
          </Tab>
          {categories.map(categoryy => {
            return (
              <Tab
                key={categoryy.id}
                title={
                  <div className="flex items-center space-x-2">
                    <img src={categoryy.image} />
                    <span>{categoryy.name}</span>
                  </div>
                }
              >
                <FlatList
                  list={deals}
                  renderItem={renderDeal}
                  renderOnScroll={true}
                  renderWhenEmpty={() => <div>List is empty!</div>}
                  sortBy={["name", { key: "name", descending: true }]}
                  // groupBy={deal =>
                  //   deal.category > 18 ? "Over 18" : "Under 18"
                  // }
                />
                {/* {deals.map((d, i) => {
                  return (
                    <MainCard
                      image={d.images[0] ? d.images[0] : ProductImage}
                      images={d.images}
                      id={d.id}
                      refresh={getDataRefresh}
                      key={d.id}
                      likeIcon={SmallFireIcon}
                      likes={d.likes}
                      isLiked={d.isLikedBy}
                      DislikeIcon={DislikeIcon}
                      productTitle={d.name}
                      productDescription={d.desc}
                      saveIcon={SaveIcon}
                      categorModal={d.category.name}
                      saleIcon={SaleIcon}
                      userName={"Floyd Miles"}
                      VerifyIcon={VerifyIcon}
                      categoryName={d.deal_merchant}
                      HourGlassIcon={HourGlassIcon}
                      dueDate={"Ends in 2 Days"}
                      numberOfComments={20}
                      commentIcon={CommentIcon}
                      discount={d.discount * 100 + "%"}
                      coupon={d.coupon}
                      url={d.url}
                    />
                  );
                })} */}
              </Tab>
            );
          })}
        </Tabs>
      </div>

      {/* <div className="container mx-auto mt-5">
        <div className=" flex flex-wrap justify-start items-center gap-2">
          {categories.map((category, i) => {
            return (
              <Button
                color="light"
                key={i}
                variant="bordered"
                size="md"
                className="bg-secondaryGray"
                startContent={<img src={category.image} />}
                onClick={() => {
                  onCategoryClickHandler(category.id);
                }}
              >
                {category.name}
              </Button>
            );
          })}
          <Button
            color="warning"
            variant="bordered"
            size="md"
            className="bg-secondaryGray"
            onClick={() => {
              // onCategoryClickHandler(category.id);
              dealsHandler();
            }}
          >
            Reset
          </Button>
        </div>
      </div>

      <div className="container mx-auto mt-5">
        <HeadingTitle title={"Hottest Deal"} icon={FireIcon} />
        {deals.map((d, i) => {
          return (
            <MainCard
              image={d.images[0] ? d.images[0] : ProductImage}
              images={d.images}
              id={d.id}
              refresh={getDataRefresh}
              key={d.id}
              likeIcon={SmallFireIcon}
              likes={d.likes}
              isLiked={d.isLikedBy}
              DislikeIcon={DislikeIcon}
              productTitle={d.name}
              productDescription={d.desc}
              saveIcon={SaveIcon}
              categorModal={d.category.name}
              saleIcon={SaleIcon}
              userName={"Floyd Miles"}
              VerifyIcon={VerifyIcon}
              categoryName={d.deal_merchant}
              HourGlassIcon={HourGlassIcon}
              dueDate={"Ends in 2 Days"}
              numberOfComments={20}
              commentIcon={CommentIcon}
              discount={d.discount * 100 + "%"}
              coupon={d.coupon}
              url={d.url}
            />
          );
        })}
      </div> */}
    </>
  );
}
