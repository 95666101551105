import React, { Suspense, useEffect, useRef } from "react";

import Routes from "main/routes";
import Header from "Components/Navbar";
import axios from "axios";
import Footer from "Components/Footer";
import Loader from "react-js-loader";

function App() {
  axios.defaults.baseURL = "https://admin.naardeals.com/api";
  const ref = useRef(null);

  // const token = fetchFromStorage("token");
  // const user = fetchFromStorage("client");

  // useEffect(() => {
  //   console.log(token, user);

  //   if (user && token) {
  //     store.dispatch(setAuth(user));
  //   }
  // }, []);

  return (
    <Suspense
      fallback={
        <Loader
          type="spinner-default"
          bgColor={"#FF6000"}
          color={"#111B2B"}
          title={"Loading"}
          size={100}
        />
      }
    >
      <Header />
      <Routes />
      <Footer />
    </Suspense>
  );
}

export default App;
