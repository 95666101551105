import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from "main/store";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { fetchFromStorage } from "library/utilities/Storage";
import { setAuth } from "library/common/actions/AuthActions";
const token = fetchFromStorage("token");
const user = fetchFromStorage("client");

// useEffect(() => {
console.log(token, user);

if (user && token) {
  store.dispatch(setAuth(user));
}
// }, []);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
