import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  CardBody,
  Chip,
  Divider,
  Image,
  Input,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import axios from "axios";
import { ReactMinimalGallery } from "react-minimal-gallery";
import SmallFireIcon from "../../resources/images/FireIcon.svg";
import FireIcon from "../../resources/images/SmallFireIcon.svg";
import MainCard from "../../Components/MainCard";
import ProductImage from "../../resources/images/ProductImage.png";
import DislikeIcon from "../../resources/images/Thumbs down.svg";
import SaveIcon from "../../resources/images/save.svg";
import SavedIcon from "../../resources/images/saved.svg";
import SaleIcon from "../../resources/images/SaleIcon.svg";
import VerifyIcon from "../../resources/images/verifiyIcon.svg";
import HourGlassIcon from "../../resources/images/hourglass-end.svg";
import CommentIcon from "../../resources/images/comment-dots.svg";
import ShareIcon from "../../resources/images/shareIcon.svg";
import Rate1 from "../../resources/images/rate1.svg";
import Rate2 from "../../resources/images/rate2.svg";
import Rate3 from "../../resources/images/rate3.svg";
import Rate4 from "../../resources/images/rate4.svg";
import Rate5 from "../../resources/images/rate5.svg";
import { Accordion, AccordionItem, Avatar } from "@nextui-org/react";
import FeedbackImage from "../../resources/images/Frame 284.png";
import { Textarea } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HeadingTitle from "Components/HeadingTitle";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Card } from "flowbite-react";
import { RatingComponent } from "react-rating-emoji";
import "react-rating-emoji/dist/index.css";
import { Tabs, Tab } from "@nextui-org/react";
import ReviewCard from "Components/ReviewCard";

export default function Deal() {
  const [liked, setLiked] = React.useState(false);
  const userData = useSelector(state => state.authReducer);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const route = useParams();
  const location = useLocation();
  const [deal, setDeal] = useState({
    images: [],
    name: "",
    category: { name: "" },
    comments: [],
    no_of_comments: 0,
    ratings: [],
  });

  const [commentt, setComment] = useState("");
  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const handleRating = newRating => {
    setRating(newRating);
  };

  const onLikeHandler = () => {
    userData.isLoggedIn == true &&
      axios.get(`/like/${route.id}`).then(response => {
        axios.get(`/deal/${route.id}`).then(response => {
          setDeal(response.data.deal);
        });
      });
  };
  const onUnLikeHandler = () => {
    userData.isLoggedIn == true &&
      axios.get(`/unlike/${route.id}`).then(response => {
        axios.get(`/deal/${route.id}`).then(response => {
          setDeal(response.data.deal);
        });
      });
  };

  const commentHandler = () => {
    axios
      .post(`/addcomment/${route.id}`, { comment: commentt })
      .then(response => {
        axios.get(`/deal/${route.id}`).then(response => {
          setDeal(response.data.deal);
        });
        setComment("");
      });
  };

  const handleFavourite = () => {
    axios.post("/favourite", { deal_id: route.id }).then(response => {
      axios.get(`/deal/${route.id}`).then(response => {
        setDeal(response.data.deal);
      });
    });
  };

  const handleFeedback = () => {
    axios
      .post("/review", {
        title: title,
        body: review,
        rating: rating,
        deal_id: route.id,
      })
      .then(response => {
        axios.get(`/deal/${route.id}`).then(response => {
          setDeal(response.data.deal);
          onClose();
        });
        // console.log(response.data);
      });
  };

  useMemo(() => {
    const getData = () => {
      axios.get(`/deal/${route.id}`).then(response => {
        setDeal(response.data.deal);
        console.log(response.data.deal);
      });
    };

    getData();
  }, []);
  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  return (
    <div className=" w-full flex flex-col justify-center mt-5 ">
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose} backdrop="blur">
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex flex-col text-3xl gap-1 self-center">
                Add Your Feedback
              </ModalHeader>
              <ModalBody>
                <Image src={FeedbackImage} />
                <div className="flex flex-row justify-between items-center gap-3">
                  <div className="flex flex-col gap-4">
                    <RatingComponent rating={rating} onClick={handleRating} />
                    <Input
                      type="text"
                      label="Title"
                      labelPlacement="outside"
                      className="mt-6"
                      size="sm"
                      value={title}
                      onChange={e => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Textarea
                      label="Feedback"
                      value={review}
                      onChange={e => {
                        setReview(e.target.value);
                      }}
                    ></Textarea>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={handleFeedback}>
                  Add Feedback
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="container flex  lg:flex-row flex-col  justify-center self-center items-center lg:items-start gap-3">
        <div className=" ">
          <ReactMinimalGallery
            images={deal.images}
            width={400}
            height={400}
            thumbnailWidth={100}
            hoverColor="#2DC573"
          />
        </div>
        <div className=" ">
          <div className="flex justify-between items-center">
            <h1 className="text-large m-4 font-medium mt-2">{deal.name}</h1>
            <div className="flex justify-center items-center">
              <Button
                isIconOnly
                variant="light"
                disabled={!userData.isLoggedIn}
                onPress={handleFavourite}
              >
                {deal.favourited == true ? (
                  <img src={SavedIcon} />
                ) : (
                  <img src={SaveIcon} />
                )}
              </Button>
              <Button
                disabled={!userData.isLoggedIn}
                onPress={onOpen}
                size="sm"
                className="bg-buttonSecondary   text-white data-[hover]:bg-foreground/10 hover:text-gray-800 "
              >
                Add Feedback
              </Button>
            </div>
          </div>
          <div className="flex justify-start items-center m-4">
            <Button
              color="light"
              variant="bordered"
              size="sm"
              className="bg-transparent"
              //   startContent={<img src={deal.category} />}
            >
              {deal.category?.name}
            </Button>
            <Divider orientation="vertical" />

            <Chip color="light">{deal.deal_merchant}</Chip>
            <Divider orientation="vertical" />
            <Chip
              color="light"
              classNames={{
                content: "text-primaryText",
              }}
            >
              {"Floyd Miles"}
            </Chip>
          </div>
          <p className="text-small max-w-[1000px] max-h-[80px] break-words m-4     text-clip overflow-hidden  text-foreground/80  ">
            {deal.desc}
          </p>
          <div className="flex gap-2 justify-start m-4 items-center">
            <Button
              //   startContent={<img src={LinkIconSVG} />}
              className="bg-white"
              size="sm"
              variant="bordered"
              as={Link}
              href={deal.url}
              target="_blank"
            >
              Open Deal
            </Button>
            <Popover placement="top">
              <PopoverTrigger>
                <Button
                  // startContent={<img src={CopyIcon} />}
                  className="bg-white"
                  size="sm"
                  variant="bordered"
                  onClick={() => {
                    navigator.clipboard.writeText(deal.coupon);
                  }}
                  disabled={!userData.isLoggedIn}
                >
                  Copy Coupon
                </Button>
              </PopoverTrigger>
              {userData.isLoggedIn && (
                <PopoverContent>
                  <div className="px-1 py-2">
                    <div className="text-small font-bold">Copied</div>
                  </div>
                </PopoverContent>
              )}
            </Popover>
            <Button size="sm">{deal.coupon}</Button>
          </div>
          <div className="flex gap-4  justify-end items-center">
            <ButtonGroup isIconOnly size="sm">
              <Button
                isIconOnly
                onPress={onLikeHandler}
                className="bg-card2bg"
                disabled={deal.isLiked == true ? true : false}
              >
                <img src={FireIcon} />
              </Button>
              <Button className="bg-card2bg">{deal.likes}</Button>
              <Button
                isIconOnly
                onPress={onUnLikeHandler}
                className="bg-card2bg"
                disabled={deal.isLiked == false ? true : false}
              >
                <img src={DislikeIcon} />
              </Button>
            </ButtonGroup>
            <Button
              startContent={<img src={CommentIcon} />}
              size="sm"
              variant="bordered"
            >
              {deal.no_of_comments}
            </Button>

            <Popover showArrow placement="bottom">
              <PopoverTrigger>
                <Button
                  startContent={<img src={ShareIcon} />}
                  size="sm"
                  variant="bordered"
                >
                  {"Share"}
                </Button>
              </PopoverTrigger>
              {userData.isLoggedIn && (
                <PopoverContent className="p-1">
                  <Card
                    shadow="none"
                    className="max-w-[300px] border-none bg-transparent"
                  >
                    <CardBody className="flex flex-row gap-2">
                      <FacebookShareButton
                        url={`https://staging.naardeals.com/deal/${deal.id}`}
                        title={deal.name}
                      >
                        <FacebookIcon round size={30} />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`https://staging.naardeals.com/deal/${deal.id}`}
                        title={deal.name}
                      >
                        <TwitterIcon round size={30} />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={`https://staging.naardeals.com/deal/${deal.id}`}
                        title={deal.name}
                      >
                        <WhatsappIcon round size={30} />
                      </WhatsappShareButton>
                      <EmailShareButton
                        url={`https://staging.naardeals.com/deal/${deal.id}`}
                        title={deal.name}
                      >
                        <EmailIcon round size={30} />
                      </EmailShareButton>
                    </CardBody>
                  </Card>
                </PopoverContent>
              )}
            </Popover>
          </div>
        </div>
      </div>
      <div className=" mt-6">
        <div className="container mx-auto  ">
          <div className="">
            <Tabs aria-label="music" className="">
              <Tab key="photos" title="Reviews" className="">
                <div className="flex flex-row justify-start items-center  flex-wrap gap-2 ">
                  {deal.ratings.map(rev => {
                    return (
                      <ReviewCard
                        title={rev.title}
                        rate={rev.rating}
                        review={rev.body}
                      />
                    );
                  })}
                </div>
              </Tab>
              <Tab key="music" title="Comments">
                <div className="container  self-center">
                  <HeadingTitle title={"Comments"} icon={SmallFireIcon} />
                  <Accordion selectionMode="multiple">
                    {deal.comments.map(comment => {
                      return (
                        <AccordionItem
                          key={comment.id}
                          aria-label="Chung Miller"
                          startContent={
                            <Avatar
                              isBordered
                              color="light"
                              name={comment.user.username}
                              radius="lg"
                              // src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                            />
                          }
                          subtitle={
                            new Date(comment.created_at)
                              .toISOString()
                              .split("T")[0]
                          }
                          title={comment.user.username}
                        >
                          {comment.comment}
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                  {userData.isLoggedIn && (
                    <div className="mt-5">
                      <Textarea
                        label="Add A Comment"
                        placeholder="Enter your comment here"
                        value={commentt}
                        onChange={e => {
                          setComment(e.target.value);
                        }}
                      />
                      <Button
                        className="bg-primaryOrange text-white mt-5"
                        disabled={commentt.length > 0 ? false : true}
                        onClick={commentHandler}
                      >
                        Send comment
                      </Button>
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
