import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Image,
  Button,
  Divider,
  Snippet,
  CardFooter,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@nextui-org/react";
import { ButtonGroup } from "@nextui-org/react";
import { Chip } from "@nextui-org/react";
import ShareIcon from "../resources/images/shareIcon.svg";
import LinkIconSVG from "../resources/images/link (1).svg";
import CopyIcon from "../resources/images/CopyIcon.svg";
import { ReactMinimalGallery } from "react-minimal-gallery";
import SavedIcon from "../resources/images/saved.svg";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export default function App({
  image,
  likeIcon,
  DislikeIcon,
  productTitle,
  isLiked,
  url,
  productDescription,
  saveIcon,
  saleIcon,
  id,
  userName,
  VerifyIcon,
  favourited,
  categoryName,
  HourGlassIcon,
  dueDate,
  numberOfComments,
  categorModal,
  discount,
  commentIcon,
  refresh,
  likes,
  images,
  coupon,
}) {
  const [liked, setLiked] = React.useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const userData = useSelector(state => state.authReducer);

  useEffect(() => {
    console.log(isLiked);
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const onLikeHandler = () => {
    axios.get(`/like/${id}`).then(response => {
      console.log(response);
      refresh();
    });
  };
  const handleFavourite = () => {
    axios.post("/favourite", { deal_id: id }).then(response => {
      refresh();
    });
  };

  const onUnLikeHandler = () => {
    axios.get(`/unlike/${id}`).then(response => {
      console.log(response);
      refresh();
    });
  };

  if (windowSize.innerWidth > 900) {
    return (
      <Card
        onPress={onOpen}
        isPressable
        className=" flex flex-row w-[100%] h-[250px] shadow-none justify-center items-center  bg-secondaryGray   mt-3"
      >
        <Modal
          isOpen={isOpen}
          placement="center"
          scrollBehavior="inside"
          backdrop="blur"
          onOpenChange={onOpenChange}
          size="4xl"
        >
          <ModalContent>
            {onClose => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  {productTitle}
                </ModalHeader>
                <ModalBody>
                  <div className="flex justify-start items-start gap-3">
                    <div className=" ">
                      <ReactMinimalGallery
                        images={images}
                        width={400}
                        height={400}
                        thumbnailWidth={100}
                        hoverColor="#2DC573"
                      />
                    </div>
                    <div className=" ">
                      <div className="flex justify-between items-center">
                        <h1 className="text-large ml-4 font-medium mt-2">
                          <Link href={`/deal/${id}`}>{productTitle}</Link>
                        </h1>
                        <Button
                          isIconOnly
                          variant="light"
                          disabled={!userData.isLoggedIn}
                          onPress={handleFavourite}
                        >
                          {favourited == true ? (
                            <img src={SavedIcon} />
                          ) : (
                            <img src={saveIcon} />
                          )}
                        </Button>
                      </div>
                      <div className="flex justify-start items-center m-4">
                        <Button
                          color="light"
                          variant="bordered"
                          size="sm"
                          className="bg-transparent"
                          // startContent={<img src={category.image} />}
                        >
                          {categorModal}
                        </Button>
                        <Divider orientation="vertical" />

                        <Chip avatar={<img src={VerifyIcon} />} color="light">
                          {userName}
                        </Chip>
                        <Divider orientation="vertical" />
                        <Chip
                          color="light"
                          classNames={{
                            content: "text-primaryText",
                          }}
                        >
                          {categoryName}
                        </Chip>
                      </div>
                      <p className="text-small max-w-[1000px] max-h-[80px] break-words m-4     text-clip overflow-hidden  text-foreground/80  ">
                        {productDescription}
                      </p>
                      <div className="flex gap-2 justify-start m-4 items-center">
                        <Button
                          startContent={<img src={LinkIconSVG} />}
                          className="bg-white"
                          size="sm"
                          variant="bordered"
                          as={Link}
                          href={url}
                          target="_blank"
                        >
                          Open Deal
                        </Button>
                        <Popover placement="top">
                          <PopoverTrigger disabled={!userData.isLoggedIn}>
                            <Button
                              startContent={<img src={CopyIcon} />}
                              className="bg-white"
                              size="sm"
                              variant="bordered"
                              onClick={() => {
                                navigator.clipboard.writeText(coupon);
                              }}
                              disabled={!userData.isLoggedIn}
                            >
                              Copy Coupon
                            </Button>
                          </PopoverTrigger>
                          {userData.isLoggedIn && (
                            <PopoverContent>
                              <div className="px-1 py-2">
                                <div className="text-small font-bold">
                                  Copied
                                </div>
                              </div>
                            </PopoverContent>
                          )}
                        </Popover>

                        <Button size="sm">{coupon}</Button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup isIconOnly size="sm">
                    <Button
                      isIconOnly
                      onPress={onLikeHandler}
                      className="bg-card2bg"
                      disabled={!userData.isLoggedIn ? true : false}
                    >
                      <img src={likeIcon} />
                    </Button>
                    <Button className="bg-card2bg">{likes}</Button>
                    <Button
                      isIconOnly
                      onPress={onUnLikeHandler}
                      className="bg-card2bg"
                      disabled={!userData.isLoggedIn ? true : false}
                    >
                      <img src={DislikeIcon} />
                    </Button>
                  </ButtonGroup>
                  <Button
                    startContent={<img src={commentIcon} />}
                    size="sm"
                    variant="bordered"
                    disabled={!userData.isLoggedIn ? true : false}
                  >
                    {numberOfComments}
                  </Button>

                  <Popover showArrow placement="bottom">
                    <PopoverTrigger>
                      <Button
                        startContent={<img src={ShareIcon} />}
                        size="sm"
                        variant="bordered"
                      >
                        {"Share"}
                      </Button>
                    </PopoverTrigger>
                    {userData.isLoggedIn && (
                      <PopoverContent className="p-1">
                        <Card
                          shadow="none"
                          className="max-w-[300px] border-none bg-transparent"
                        >
                          <CardBody className="flex flex-row gap-2">
                            <FacebookShareButton
                              url={`https://staging.naardeals.com/deal/${id}`}
                              title={productTitle}
                            >
                              <FacebookIcon round size={30} />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={`https://staging.naardeals.com/deal/${id}`}
                              title={productTitle}
                            >
                              <TwitterIcon round size={30} />
                            </TwitterShareButton>
                            <WhatsappShareButton
                              url={`https://staging.naardeals.com/deal/${id}`}
                              title={productTitle}
                            >
                              <WhatsappIcon round size={30} />
                            </WhatsappShareButton>
                            <EmailShareButton
                              url={`https://staging.naardeals.com/deal/${id}`}
                              title={productTitle}
                            >
                              <EmailIcon round size={30} />
                            </EmailShareButton>
                          </CardBody>
                        </Card>
                      </PopoverContent>
                    )}
                  </Popover>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <CardBody className="w-[202px] h-[100%]  p-0">
          <div className="">
            <Image
              alt="Album cover"
              className="object-fill h-[250px]"
              src={image}
              radius="none"
              // width="202px"
            />
          </div>
        </CardBody>
        <CardFooter className="">
          <div className="flex items-start justify-start  w-[100%]">
            <div className="flex flex-col w-[100%]">
              <div className="flex w-[100%]">
                <div className="flex flex-col items-start gap-0  w-[100%]">
                  <ButtonGroup isIconOnly size="sm">
                    <Button
                      isIconOnly
                      onPress={onLikeHandler}
                      disabled={!userData.isLoggedIn ? true : false}
                      className="bg-card2bg"
                    >
                      <img src={likeIcon} />
                    </Button>
                    <Button className="bg-card2bg" disabled={true}>
                      {likes}
                    </Button>
                    <Button
                      isIconOnly
                      className="bg-card2bg"
                      disabled={!userData.isLoggedIn ? true : false}
                      onPress={onUnLikeHandler}
                    >
                      <img src={DislikeIcon} />
                    </Button>
                  </ButtonGroup>
                  <div className="flex flex-row justify-between  w-[100%]">
                    <h1 className="text-large font-medium mt-2">
                      {productTitle}
                    </h1>
                    <div className="flex justify-center items-center">
                      <Button
                        isIconOnly
                        variant="light"
                        disabled={!userData.isLoggedIn}
                        onPress={handleFavourite}
                      >
                        {favourited == true ? (
                          <img src={SavedIcon} />
                        ) : (
                          <img src={saveIcon} />
                        )}
                      </Button>
                      <Chip
                        classNames={{
                          base: "bg-primaryOrange",
                          content: "drop-shadow shadow-black text-white",
                        }}
                        avatar={<img src={saleIcon} />}
                      >
                        {discount}
                      </Chip>
                    </div>
                  </div>

                  <p className="text-small max-w-[1000px] max-h-[80px] break-words     text-clip overflow-hidden  text-foreground/80  ">
                    {productDescription}
                  </p>
                  <div className="flex justify-between w-[100%]">
                    <div className="flex justify-center items-center mt-2">
                      <Chip avatar={<img src={VerifyIcon} />} color="light">
                        {userName}
                      </Chip>
                      <Divider orientation="vertical" />
                      <Chip
                        color="light"
                        classNames={{
                          content: "text-primaryText",
                        }}
                      >
                        {categoryName}
                      </Chip>
                    </div>
                    <div className="">
                      <Chip avatar={<img src={HourGlassIcon} />} color="light">
                        {dueDate}
                      </Chip>
                    </div>
                  </div>
                  <div className="flex justify-between w-[100%]">
                    <div className="flex justify-center items-center mt-2">
                      <Chip
                        avatar={<img src={commentIcon} />}
                        // isPressable
                        color="light"
                        disabled={!userData.isLoggedIn}
                      >
                        {numberOfComments}
                      </Chip>

                      <Popover showArrow placement="bottom">
                        <PopoverTrigger>
                          <Button
                            color="light"
                            isIconOnly
                            disabled={!userData.isLoggedIn}
                          >
                            <img src={ShareIcon} />
                          </Button>
                        </PopoverTrigger>
                        {userData.isLoggedIn && (
                          <PopoverContent className="p-1">
                            <Card
                              shadow="none"
                              className="max-w-[300px] border-none bg-transparent"
                            >
                              <CardBody className="flex flex-row gap-2">
                                <FacebookShareButton
                                  url={`https://staging.naardeals.com/deal/${id}`}
                                  title={productTitle}
                                >
                                  <FacebookIcon round size={30} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  url={`https://staging.naardeals.com/deal/${id}`}
                                  title={productTitle}
                                >
                                  <TwitterIcon round size={30} />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                  url={`https://staging.naardeals.com/deal/${id}`}
                                  title={productTitle}
                                >
                                  <WhatsappIcon round size={30} />
                                </WhatsappShareButton>
                                <EmailShareButton
                                  url={`https://staging.naardeals.com/deal/${id}`}
                                  title={productTitle}
                                >
                                  <EmailIcon round size={30} />
                                </EmailShareButton>
                              </CardBody>
                            </Card>
                          </PopoverContent>
                        )}
                      </Popover>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                      <Button
                        startContent={<img src={LinkIconSVG} />}
                        className="bg-white"
                        size="sm"
                        variant="bordered"
                        as={Link}
                        href={url}
                        target="_blank"
                      >
                        Open Deal
                      </Button>
                      <Popover placement="top">
                        <PopoverTrigger disabled={!userData.isLoggedIn}>
                          <Button
                            startContent={<img src={CopyIcon} />}
                            className="bg-white"
                            size="sm"
                            variant="bordered"
                            onClick={() => {
                              navigator.clipboard.writeText(coupon);
                            }}
                            disabled={!userData.isLoggedIn}
                          >
                            Copy Coupon
                          </Button>
                        </PopoverTrigger>
                        {userData.isLoggedIn && (
                          <PopoverContent>
                            <div className="px-1 py-2">
                              <div className="text-small font-bold">Copied</div>
                            </div>
                          </PopoverContent>
                        )}
                      </Popover>
                      <Button size="sm" disabled={!userData.isLoggedIn}>
                        {coupon}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  } else {
    return (
      <div className="w-[100%]  flex justify-center items-center">
        <Card className="w-[90%]  mt-3" onPress={onOpen} isPressable>
          <Modal
            isOpen={isOpen}
            placement="center"
            scrollBehavior="inside"
            backdrop="blur"
            onOpenChange={onOpenChange}
            size="4xl"
            className=" w-[100%]"
          >
            <ModalContent>
              {onClose => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {productTitle}
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex justify-start items-start gap-3">
                      <div className=" ">
                        <ReactMinimalGallery
                          images={images}
                          width={"100%"}
                          height={400}
                          thumbnailWidth={100}
                          hoverColor="#2DC573"
                        />
                        <div className=" ">
                          <h1 className="text-large ml-4 font-medium mt-2">
                            <Link href={`/deal/${id}`}>{productTitle}</Link>
                          </h1>
                          <div className="flex justify-start items-center m-4">
                            <Button
                              color="light"
                              variant="bordered"
                              size="md"
                              className="bg-secondaryGray"
                              // startContent={<img src={category.image} />}
                            >
                              {"category"}
                            </Button>
                            <Divider orientation="vertical" />

                            <Chip
                              avatar={<img src={VerifyIcon} />}
                              color="light"
                            >
                              {userName}
                            </Chip>
                            <Divider orientation="vertical" />
                            <Chip
                              color="light"
                              classNames={{
                                content: "text-primaryText",
                              }}
                            >
                              {categoryName}
                            </Chip>
                          </div>
                          <p className="text-small max-w-[1000px] max-h-[80px] break-words m-4     text-clip overflow-hidden  text-foreground/80  ">
                            {productDescription}
                          </p>
                          <div className="flex gap-2 justify-start m-4 items-center">
                            <Button
                              startContent={<img src={LinkIconSVG} />}
                              className="bg-white"
                              size="sm"
                              variant="bordered"
                              as={Link}
                              href={url}
                              target="_blank"
                            >
                              Open Deal
                            </Button>
                            <Popover placement="top">
                              <PopoverTrigger disabled={!userData.isLoggedIn}>
                                <Button
                                  startContent={<img src={CopyIcon} />}
                                  className="bg-white"
                                  size="sm"
                                  variant="bordered"
                                  onClick={() => {
                                    navigator.clipboard.writeText(coupon);
                                  }}
                                  disabled={!userData.isLoggedIn}
                                >
                                  Copy Coupon
                                </Button>
                              </PopoverTrigger>
                              {userData.isLoggedIn && (
                                <PopoverContent>
                                  <div className="px-1 py-2">
                                    <div className="text-small font-bold">
                                      Copied
                                    </div>
                                  </div>
                                </PopoverContent>
                              )}
                            </Popover>
                            <Button size="sm">{coupon}</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup isIconOnly size="sm">
                      <Button
                        isIconOnly
                        className="bg-card2bg"
                        onPress={onLikeHandler}
                        disabled={!userData.isLoggedIn ? true : false}
                      >
                        <img src={likeIcon} />
                      </Button>
                      <Button className="bg-card2bg">{likes}</Button>
                      <Button
                        isIconOnly
                        className="bg-card2bg"
                        onPress={onUnLikeHandler}
                        disabled={!userData.isLoggedIn ? true : false}
                      >
                        <img src={DislikeIcon} />
                      </Button>
                    </ButtonGroup>
                    <Button
                      startContent={<img src={commentIcon} />}
                      size="sm"
                      variant="bordered"
                      disabled={!userData.isLoggedIn ? true : false}
                    >
                      {numberOfComments}
                    </Button>

                    <Popover showArrow placement="bottom">
                      <PopoverTrigger>
                        <Button
                          startContent={<img src={ShareIcon} />}
                          size="sm"
                          variant="bordered"
                        >
                          {"Share"}
                        </Button>
                      </PopoverTrigger>
                      {userData.isLoggedIn && (
                        <PopoverContent className="p-1">
                          <Card
                            shadow="none"
                            className="max-w-[300px] border-none bg-transparent"
                          >
                            <CardBody className="flex flex-row gap-2">
                              <FacebookShareButton
                                url={`https://staging.naardeals.com/deal/${id}`}
                                title={productTitle}
                              >
                                <FacebookIcon round size={30} />
                              </FacebookShareButton>
                              <TwitterShareButton
                                url={`https://staging.naardeals.com/deal/${id}`}
                                title={productTitle}
                              >
                                <TwitterIcon round size={30} />
                              </TwitterShareButton>
                              <WhatsappShareButton
                                url={`https://staging.naardeals.com/deal/${id}`}
                                title={productTitle}
                              >
                                <WhatsappIcon round size={30} />
                              </WhatsappShareButton>
                              <EmailShareButton
                                url={`https://staging.naardeals.com/deal/${id}`}
                                title={productTitle}
                              >
                                <EmailIcon round size={30} />
                              </EmailShareButton>
                            </CardBody>
                          </Card>
                        </PopoverContent>
                      )}
                    </Popover>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
          <CardBody className="overflow-visible p-0">
            <Image
              // shadow="sm"
              // radius="lg"
              // height={173}
              width="100%"
              className="w-full object-contain h-[173px]"
              src={image}
            />
            <Button
              isIconOnly
              variant="light"
              disabled={!userData.isLoggedIn}
              onPress={handleFavourite}
            >
              {favourited == true ? (
                <img src={SavedIcon} />
              ) : (
                <img src={saveIcon} />
              )}
            </Button>
            <Chip
              classNames={{
                base: "bg-primaryOrange",
                content: "drop-shadow shadow-black text-white",
              }}
              className=" absolute bottom-4 right-4"
              avatar={<img src={saleIcon} />}
            >
              15%
            </Chip>
          </CardBody>
          <CardFooter className="flex flex-col text-small justify-between">
            <div className="flex flex-row w-[90%]  justify-between items-center">
              <ButtonGroup isIconOnly size="sm">
                <Button
                  isIconOnly
                  className="bg-card2bg"
                  onPress={onLikeHandler}
                  disabled={!userData.isLoggedIn ? true : false}
                >
                  <img src={likeIcon} />
                </Button>
                <Button className="bg-card2bg">{likes}</Button>
                <Button
                  isIconOnly
                  className="bg-card2bg"
                  onPress={onUnLikeHandler}
                  disabled={!userData.isLoggedIn ? true : false}
                >
                  <img src={DislikeIcon} />
                </Button>
              </ButtonGroup>
              <Chip avatar={<img src={HourGlassIcon} />} color="light">
                {dueDate}
              </Chip>
            </div>
            <div className="flex flex-row w-[90%]  justify-between items-center">
              <h1 className="text-large font-medium mt-2">{productTitle}</h1>
            </div>
            <div className="flex flex-row w-[90%]  justify-between items-center">
              <p className="text-small max-w-[1000px] text-foreground/80  ">
                {productDescription.slice(
                  productDescription.length / 2,
                  productDescription.length,
                )}
              </p>
            </div>
            <div className="flex flex-row w-[90%]  justify-start items-center">
              <Chip avatar={<img src={VerifyIcon} />} color="light">
                {userName}
              </Chip>
              <Divider orientation="vertical" />
              <Chip
                color="light"
                classNames={{
                  content: "text-primaryText",
                }}
              >
                {categoryName}
              </Chip>
            </div>
            <div className="flex flex-row w-[90%]  justify-between items-center">
              <div className="flex justify-center items-center mt-2">
                <Chip avatar={<img src={commentIcon} />} color="light">
                  {numberOfComments}
                </Chip>

                <Popover showArrow placement="bottom">
                  <PopoverTrigger>
                    <Button color="light" isIconOnly>
                      <img src={ShareIcon} />
                    </Button>
                  </PopoverTrigger>
                  {userData.isLoggedIn && (
                    <PopoverContent className="p-1">
                      <Card
                        shadow="none"
                        className="max-w-[300px] border-none bg-transparent"
                      >
                        <CardBody className="flex flex-row gap-2">
                          <FacebookShareButton
                            url={`https://staging.naardeals.com/deal/${id}`}
                            title={productTitle}
                          >
                            <FacebookIcon round size={30} />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={`https://staging.naardeals.com/deal/${id}`}
                            title={productTitle}
                          >
                            <TwitterIcon round size={30} />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={`https://staging.naardeals.com/deal/${id}`}
                            title={productTitle}
                          >
                            <WhatsappIcon round size={30} />
                          </WhatsappShareButton>
                          <EmailShareButton
                            url={`https://staging.naardeals.com/deal/${id}`}
                            title={productTitle}
                          >
                            <EmailIcon round size={30} />
                          </EmailShareButton>
                        </CardBody>
                      </Card>
                    </PopoverContent>
                  )}
                </Popover>
              </div>
              <Button size="sm">MHR30</Button>
            </div>
            <div className="flex flex-row w-[90%]  justify-between items-center">
              <Button
                startContent={<img src={LinkIconSVG} />}
                className="bg-white"
                size="md"
                variant="bordered"
              >
                Open Deal
              </Button>
              <Popover placement="top">
                <PopoverTrigger disabled={!userData.isLoggedIn}>
                  <Button
                    startContent={<img src={CopyIcon} />}
                    className="bg-white"
                    size="sm"
                    variant="bordered"
                    onClick={() => {
                      navigator.clipboard.writeText(coupon);
                    }}
                    disabled={!userData.isLoggedIn}
                  >
                    Copy Coupon
                  </Button>
                </PopoverTrigger>
                {userData.isLoggedIn && (
                  <PopoverContent>
                    <div className="px-1 py-2">
                      <div className="text-small font-bold">Copied</div>
                    </div>
                  </PopoverContent>
                )}
              </Popover>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}
