import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Image,
} from "@nextui-org/react";
import { Link as RoutingLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchLight from "../resources/images/bell 2.svg";
import SearchDark from "../resources/images/bell (1) 2.svg";
import Bell from "../resources/images/lightBell.svg";
import AuthReducer from "library/common/reducers/AuthReducer";
import store from "main/store";
import { getAuth, setAuth } from "library/common/actions/AuthActions";
import { useSelector } from "react-redux";
import { AvatarGroup, AvatarIcon } from "@nextui-org/react";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  User,
} from "@nextui-org/react";
import { clearStorage } from "library/utilities/Storage";
import Logo from "../resources/images/logo.svg";
import DarkLogo from "../resources/images/darkLogo.svg";
import { Redirect } from "react-router-dom";
import useWindowDimensions from "./width";

export default function Header({ isLoggedIn }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [authPages, setAuthPages] = React.useState(false);
  const [user, setUser] = React.useState({ username: "" });
  const userData = useSelector(state => state.authReducer);

  const { height, width } = useWindowDimensions();

  let location = useLocation();
  React.useEffect(() => {
    console.log(userData);
    if (userData.isLoggedIn) {
      setUser(userData.user);
    }
    if (location.pathname === "/signup") {
      setAuthPages(true);
    }
    if (location.pathname === "/login") {
      setAuthPages(true);
    }
  }, [location]);

  const logoutHandler = () => {
    clearStorage();
    store.dispatch(
      setAuth({
        user: null,
        token: null,
      }),
    );
    window.location.reload();
  };

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      className={authPages !== true ? "bg-primaryBg" : ""}
    >
      <NavbarContent justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand className="w-[150px]">
          <Link color="foreground" href="/" size="lg">
            {authPages == true ? (
              <Image src={Logo} className=" w-[150px]" />
            ) : (
              <Image src={DarkLogo} className=" w-[150px]" />
            )}
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex gap-12 pr-[200px]"
        justify="start"
      >
        <NavbarItem>
          <Link
            color="foreground"
            href="/"
            className={
              authPages == true
                ? "text-primarygray font-normal "
                : "text-white font-normal"
            }
          >
            Home
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            href="/categories"
            color="foreground"
            className={
              authPages == true
                ? "text-primarygray font-normal "
                : "text-white font-normal"
            }
          >
            Categories
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            color="foreground"
            href="#"
            className={
              authPages == true
                ? "text-primarygray font-normal "
                : "text-white font-normal"
            }
          >
            Blogs
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            color="foreground"
            href="#"
            className={
              authPages == true
                ? "text-primarygray font-normal "
                : "text-white font-normal"
            }
          >
            Post Deal
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        {authPages === false ? (
          <Button
            isIconOnly
            color="transparent"
            className="mr-2"
            aria-label="Like"
          >
            <img src={SearchLight} />
          </Button>
        ) : (
          <Button
            isIconOnly
            color="transparent"
            className="mr-2"
            aria-label="Like"
          >
            <img src={SearchDark} />
          </Button>
        )}
        {userData.isLoggedIn == true && (
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Button
                isIconOnly
                color="white"
                className="mr-2"
                aria-label="Like"
              >
                <img src={Bell} />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Profile Actions" variant="flat">
              <DropdownItem
                key="profile"
                className="h-14 gap-2"
                textValue="not-2"
              >
                <div className="flex flex-row justify-center items-center gap-3">
                  <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
                  <div className="">
                    <div className="flex justify-between items-center">
                      <h2>Carmen Parksouth</h2>
                      <p>1 day</p>
                    </div>
                    <p className="w-[300px]">
                      Communed on your deal MasterChef Knife Set of 3 Kitchen
                      Knives
                    </p>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem key="settings" textValue="not-1">
                <div className="flex flex-row justify-center items-center gap-3">
                  <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
                  <div className="">
                    <div className="flex justify-between items-center">
                      <h2>Carmen Parksouth</h2>
                      <p>1 day</p>
                    </div>
                    <p className="w-[300px]">
                      Communed on your deal MasterChef Knife Set of 3 Kitchen
                      Knives
                    </p>
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}

        {userData.isLoggedIn == false ? (
          <>
            <NavbarItem>
              <Link
                href="/signup"
                underline="hover"
                className={
                  authPages == true
                    ? "text-primarygray font-normal "
                    : "text-white font-normal"
                }
              >
                Sign Up
              </Link>
            </NavbarItem>
            <NavbarItem className="hidden  lg:flex">
              <Link
                href="/login"
                className={
                  authPages == true
                    ? "text-primarygray font-normal "
                    : "text-white font-normal"
                }
                underline="hover"
              >
                Log In
              </Link>
            </NavbarItem>
          </>
        ) : (
          <>
            <NavbarItem>
              <Dropdown placement="bottom-end">
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    as="button"
                    className="transition-transform"
                    color="primaryBg"
                    name={user.username}
                    size="sm"
                    // src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                  />
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                  <DropdownItem key="profile" className="h-14 gap-2">
                    <p className="font-semibold">Signed in as</p>
                    <p className="font-semibold">{user.email}</p>
                  </DropdownItem>
                  <DropdownItem key="profile">My Profile</DropdownItem>

                  <DropdownItem
                    key="logout"
                    color="danger"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {/* <Dropdown placement="bottom-start" backdrop="blur">
                <DropdownTrigger>
                  {width > 600 ? (
                    <User
                      as="button"
                      avatarProps={{
                        isBordered: false,
                        name: user.username,
                      }}
                      className={
                        authPages == true
                          ? "text-primarygray font-normaltransition-transform "
                          : "text-white font-normal transition-transform"
                      }
                      name={"welcome, " + user.username}
                    />
                  ) : (
                    <Avatar
                      isBordered
                      name={user.username}
                      as="button"
                      className="transition-transform"
                    />
                  )}
                </DropdownTrigger>
                <DropdownMenu aria-label="User Actions" variant="flat">
                  <DropdownItem key="profilee" className="h-14 gap-2">
                    <p className="font-bold">Signed in as</p>
                    <p className="font-bold">@{user.username}</p>
                  </DropdownItem>

                  <DropdownItem
                    key="logout"
                    color="danger"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
            </NavbarItem>
          </>
        )}
      </NavbarContent>
      <NavbarMenu>
        <NavbarMenuItem>
          <Link color={"foreground"} className="w-full" href={"/"} size="lg">
            Home
          </Link>
          <Link
            color={"foreground"}
            className="w-full"
            href={"/categories"}
            size="lg"
          >
            Categories
          </Link>
          <Link color={"foreground"} className="w-full" href={"/"} size="lg">
            Post A Deal
          </Link>
          <Link color={"foreground"} className="w-full" href={"/"} size="lg">
            Blog
          </Link>
        </NavbarMenuItem>
        {/* {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              color={
                index === 2
                  ? "primary"
                  : index === menuItems.length - 1
                  ? "danger"
                  : "foreground"
              }
              className="w-full"
              href={
                item == "Home"
                  ? "/"
                  : item == "Categories"
                  ? "/categories"
                  : "#"
              }
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))} */}
      </NavbarMenu>
    </Navbar>
  );
}
