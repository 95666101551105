import React from "react";

import SideRight from "../../resources/images/side right (1).png";
import GoogleIcon from "../../resources/images/search.svg";
import FaceBookIcon from "../../resources/images/facebook.svg";
import { Button, Divider, Input, Link } from "@nextui-org/react";
import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";
import axiosInstance from "main/axios";
import { saveToStorage } from "library/utilities/Storage";
import store from "main/store";
import { setAuth } from "library/common/actions/AuthActions";
export default function Login() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordErr, setPasswordErr] = React.useState([]);
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [usernameErr, setUsernameErr] = React.useState("");

  const toggleVisibility = () => setIsVisible(!isVisible);

  const loginHandler = () => {
    setIsLoading(true);
    var data = {
      username: username,
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
    };

    axiosInstance
      .post("/register", data)
      .then(response => {
        saveToStorage("client", response.data.client);
        saveToStorage("token", response.data.access_token);
        store.dispatch(
          setAuth({
            user: response.data.client,
            token: response.data.access_token,
          }),
        );
        setIsLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response.data.errors) {
          if (err.response.data.errors.username) {
            setUsernameErr(err.response.data.errors.username);
          } else {
            setUsernameErr("");
          }
          if (err.response.data.errors.email) {
            setEmailErr(err.response.data.errors.email);
          } else {
            setEmailErr("");
          }
          if (err.response.data.errors.password) {
            setPasswordErr(err.response.data.errors.password);
          } else {
            setPasswordErr("");
          }
        }
      });
  };
  return (
    <div className=" flex justify-center align-middle">
      <div className="flex xl:justify-between lg:justify-between md:justify-center  sm:justify-center align-middle w-[90%]">
        <div>
          <div className="">
            <h1 className="  text-[28px] font-[600]">Sign Up</h1>
            <p>Welcome back! Select method to Sign Up:</p>
          </div>
          <div className="  mt-6 flex flex-wrap  justify-center align-middle">
            <Button
              color="foreground"
              className="w-[190px] m-2"
              variant="bordered"
              startContent={<img src={GoogleIcon} />}
            >
              Google
            </Button>
            <Button
              color="foreground"
              className="w-[190px] m-2"
              variant="bordered"
              startContent={<img src={FaceBookIcon} />}
            >
              Facebook
            </Button>
          </div>
          <Divider className="my-4" />
          <div className="flex w-full flex-col flex-wrap md:flex-nowrap gap-4">
            <Input
              type="text"
              label="Username"
              labelPlacement={"outside"}
              placeholder="username"
              isRequired
              errorMessage={usernameErr}
              isInvalid={usernameErr.length == 0 ? false : true}
              onChange={e => setUsername(e.target.value)}
              radius="sm"
              variant="bordered"
            />
            <Input
              type="email"
              label="Email"
              labelPlacement={"outside"}
              placeholder="Example@gmail.com"
              isRequired
              errorMessage={emailErr}
              isInvalid={emailErr.length == 0 ? false : true}
              onChange={e => setEmail(e.target.value)}
              radius="sm"
              variant="bordered"
            />
            <Input
              label="Password"
              variant="bordered"
              labelPlacement={"outside"}
              errorMessage={passwordErr}
              isInvalid={passwordErr.length == 0 ? false : true}
              onChange={e => setPassword(e.target.value)}
              isRequired
              radius="sm"
              placeholder="Enter your password"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
            <Input
              label="Confirm Password"
              variant="bordered"
              labelPlacement={"outside"}
              // errorMessage={passwordErr}
              // isInvalid={emailErr.length == 0 ? false : true}
              onChange={e => setPasswordConfirmation(e.target.value)}
              isRequired
              radius="sm"
              placeholder="Enter your password"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
            <Button
              className="bg-primaryOrange text-white"
              onClick={loginHandler}
              isLoading={isLoading}
            >
              Sign Up
            </Button>
          </div>
          <div className="flex mt-6 justify-between align-middle text-[12px]">
            <Link color="foreground" size="sm" href="/login">
              Already have an account? Login
            </Link>
            {/* <Link color="foreground" size="sm" href="/">
              Forget Password?
            </Link> */}
          </div>
        </div>
        <div className="hidden  lg:flex self-end">
          <img src={SideRight} width={"100%"} />
        </div>
      </div>
    </div>
  );
}
