import { Button } from "@nextui-org/react";
import React from "react";

export default function HeadingTitle({
  title,
  icon,
  hasSideButton,
  buttonTitle,
}) {
  return (
    <div className=" flex  md:flex-row lg:flex-row sm:flex-col justify-between  w-[100%] items-center gap-2 mt-4 ">
      <div className="flex justify-center items-center gap-2 mt-4 ml-5">
        <h1 className=" text-primarygray text-2xl font-semibold">{title}</h1>
        <img src={icon} />
      </div>
      {hasSideButton && (
        <>
          <Button className="bg-buttonSecondary   text-white data-[hover]:bg-foreground/10 hover:text-gray-800 ">
            {buttonTitle}
          </Button>
        </>
      )}
    </div>
  );
}
