import React, { lazy, useEffect } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  withRouter,
  Switch,
  useLocation,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import SignUp from "modules/SignUp";
import Login from "modules/Login";
import { fetchFromStorage } from "library/utilities/Storage";
import store from "main/store";
import { setAuth } from "library/common/actions/AuthActions";
import ForgetPassword from "modules/ForgetPassword";
import ConctactUs from "modules/ContactUs";
import Categories from "modules/Categories";
import axios from "axios";
import Deal from "modules/Deal";

const Home = lazy(() => import("modules/Home"));
const Dashboard = lazy(() => import("modules/Dashboard"));

const Routes = ({ isLoggedIn }) => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const token = fetchFromStorage("token");
  const user = fetchFromStorage("client");

  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };

  // useEffect(() => {
  //   const checkUser = () => {
  //     if (user && token) {
  //       store.dispatch(setAuth(user));
  //     }
  //   };

  //   return [checkUser()];
  // }, [location.pathname]);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          // if (isLoggedIn && from) {
          //   return <Redirect to={from} />;
          // } else {
          return <Home isLoggedIn={isLoggedIn} />;
          // }
        }}
      />
      <Route
        exact
        path="/categories"
        render={() => {
          // if (isLoggedIn && from) {
          //   return <Redirect to={from} />;
          // } else {
          return <Categories isLoggedIn={isLoggedIn} />;
          // }
        }}
      />
      <Route
        exact
        path="/login"
        render={() => {
          if (isLoggedIn && from) {
            return <Redirect to={from} />;
          } else {
            return <Login isLoggedIn={isLoggedIn} />;
          }
        }}
      />
      <Route
        exact
        path="/deal/:id"
        render={() => {
          // if (isLoggedIn && from) {
          // return <Redirect to={from} />;
          // } else {
          return <Deal isLoggedIn={isLoggedIn} />;
          // }
        }}
      />
      <Route
        exact
        path="/forgetpassword"
        render={() => {
          if (isLoggedIn && from) {
            return <Redirect to={from} />;
          } else {
            return <ForgetPassword isLoggedIn={isLoggedIn} />;
          }
        }}
      />
      <Route
        exact
        path="/contactus"
        render={() => {
          if (isLoggedIn && from) {
            return <Redirect to={from} />;
          } else {
            return <ConctactUs isLoggedIn={isLoggedIn} />;
          }
        }}
      />
      <Route
        exact
        path="/signup"
        render={() => {
          if (isLoggedIn && from) {
            return <Redirect to={from} />;
          } else {
            return <SignUp />;
          }
        }}
      />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <Route
        exact
        path="*"
        render={() => {
          return <Redirect to={from} />;
        }}
      />
    </Switch>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    user: authReducer.user,
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
