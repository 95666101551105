import React from "react";

import SideRight from "../../resources/images/side right (1).png";
import GoogleIcon from "../../resources/images/search.svg";
import FaceBookIcon from "../../resources/images/facebook.svg";
import { Button, Divider, Input, Link } from "@nextui-org/react";
import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";

import axios from "../../main/axios/index";
import AuthReducer from "library/common/reducers/AuthReducer";
import { getAuth, setAuth } from "library/common/actions/AuthActions";
import { saveToStorage } from "library/utilities/Storage";
import store from "main/store";
export default function Login({ isLoggedIn }) {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordErr, setPasswordErr] = React.useState("");

  const toggleVisibility = () => setIsVisible(!isVisible);

  const loginHandler = () => {
    setIsLoading(true);
    var data = {
      email: email,
      password: password,
    };

    axios
      .post("/login", data)
      .then(response => {
        saveToStorage("client", response.data.client);
        saveToStorage("token", response.data.access_token);
        store.dispatch(
          setAuth({
            user: response.data.client,
            token: response.data.access_token,
          }),
        );
        setIsLoading(false);
        window.location.reload();
      })
      .catch(err => {
        if (err.response.data.errors) {
          setEmailErr(err.response.data.errors.email[0]);
          setPasswordErr(err.response.data.errors.password[0]);
        } else {
          if (err.response.data) {
            setEmailErr("Invalid Email Or Password");
            setPasswordErr("");
          }
        }
        setIsLoading(false);
      });
  };

  return (
    <div className=" flex justify-center align-middle">
      <div className="flex xl:justify-between lg:justify-between md:justify-center  sm:justify-center align-middle w-[90%]">
        <div>
          <div className="">
            <h1 className="  text-[28px] font-[600]">Login to your Account</h1>
            <p>Welcome back! Select method to log in:</p>
          </div>
          <div className="  mt-6 flex flex-wrap  justify-center align-middle">
            <Button
              color="foreground"
              className="w-[190px] m-2"
              variant="bordered"
              startContent={<img src={GoogleIcon} />}
            >
              Google
            </Button>
            <Button
              color="foreground"
              className="w-[190px] m-2"
              variant="bordered"
              startContent={<img src={FaceBookIcon} />}
            >
              Facebook
            </Button>
          </div>
          <Divider className="my-4" />
          <div className="flex w-full flex-col flex-wrap md:flex-nowrap gap-4">
            <Input
              type="email"
              label="Email"
              labelPlacement={"outside"}
              placeholder="Example@gmail.com"
              isRequired
              errorMessage={emailErr}
              isInvalid={emailErr.length == 0 ? false : true}
              onChange={e => setEmail(e.target.value)}
              radius="sm"
              variant="bordered"
            />
            <Input
              label="Password"
              variant="bordered"
              labelPlacement={"outside"}
              isRequired
              errorMessage={passwordErr}
              isInvalid={passwordErr.length == 0 ? false : true}
              onChange={e => setPassword(e.target.value)}
              radius="sm"
              placeholder="Enter your password"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
            <Button
              onClick={loginHandler}
              isLoading={isLoading}
              className="bg-primaryOrange text-white"
            >
              Login
            </Button>
          </div>
          <div className="flex mt-6 justify-between align-middle text-[12px]">
            <Link color="foreground" size="sm" href="/signup">
              Don’t have an account? Sign Up
            </Link>
            <Link color="foreground" size="sm" href="/forgetpassword">
              Forget Password?
            </Link>
          </div>
        </div>
        <div className="hidden  lg:flex self-end">
          <img src={SideRight} width={"100%"} />
        </div>
      </div>
    </div>
  );
}
