import React, { useState } from "react";

import SideRight from "../../resources/images/side right (1).png";
import GoogleIcon from "../../resources/images/search.svg";
import FaceBookIcon from "../../resources/images/facebook.svg";
import { Button, Divider, Input, Link, useDisclosure } from "@nextui-org/react";
import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";

import axios from "../../main/axios/index";
import AuthReducer from "library/common/reducers/AuthReducer";
import { getAuth, setAuth } from "library/common/actions/AuthActions";
import { saveToStorage } from "library/utilities/Storage";
import store from "main/store";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@nextui-org/react";
import { MailIcon } from "./MailIcon.jsx";
import { LockIcon } from "./LockIcon.jsx";

export default function ForgetPassword({ isLoggedIn }) {
  const [isVisible, setIsVisible] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordErr, setPasswordErr] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  const [code, setCode] = React.useState("");
  const [codeErr, setCodeErr] = React.useState("");
  const [codeSuccess, setCodeSuccess] = React.useState(false);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [emailExist, setEmailExist] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const loginHandler = () => {
    var data = {
      email: email,
    };

    axios
      .post("/password/email", data)
      .then(response => {
        if (response.status == 200) {
          onOpenChange(true);
          setEmailExist(true);
        }
      })
      .catch(err => {
        if (err.response.data.errors) {
          setEmailErr(err.response.data.errors.email);
        } else {
          if (err.response.data) {
            setEmailErr("Invalid Email Or Password");
          }
        }
      });
  };

  const resetHandler = () => {
    var data = {
      code: code,
    };

    axios
      .post("/password/code/check", data)
      .then(response => {
        if (response.status == 200) {
          onOpenChange(true);
          setCodeSuccess(true);
        }
      })
      .catch(err => {
        if (err.response.data.errors) {
          setCodeErr(err.response.data.errors.code);
        } else {
          if (err.response.data) {
            setCodeErr("Invalid Email Or Password");
          }
        }
      });
  };
  const newPasswordHandler = () => {
    var data = {
      code: code,
      password: password,
      password_confirmation: passwordConfirmation,
    };

    axios
      .post("/password/reset", data)
      .then(response => {
        console.log(response.data);
        if (response.status == 200) {
          onOpenChange(false);
          window.location.href = "/login";
        }
      })
      .catch(err => {
        if (err.response.data.errors) {
          if (err.response.data.errors.password) {
            setPasswordErr(err.response.data.errors.password);
          } else {
            setPasswordErr("");
          }
        }
      });
  };

  return (
    <div className=" flex justify-center align-middle">
      <div className="flex xl:justify-between lg:justify-between md:justify-center sm:justify-center align-middle w-[80%]">
        <div className="lg:w-[40%] w-full">
          <div className="">
            <h1 className="  text-[28px] font-[600]">Forget Password</h1>
            <p>Enter email to reset password:</p>
          </div>

          <Divider className="my-4" />
          <div className="flex w-full flex-col flex-wrap md:flex-nowrap gap-4">
            {emailExist == false ? (
              <>
                <Input
                  type="email"
                  label="Email"
                  labelPlacement={"outside"}
                  placeholder="Example@gmail.com"
                  isRequired
                  errorMessage={emailErr}
                  isInvalid={emailErr.length == 0 ? false : true}
                  onChange={e => setEmail(e.target.value)}
                  radius="sm"
                  variant="bordered"
                />

                <Button
                  onClick={loginHandler}
                  className="bg-primaryOrange text-white"
                >
                  Send Link
                </Button>
              </>
            ) : (
              <>
                {" "}
                <Input
                  type="text"
                  label="Reset Code"
                  labelPlacement={"outside"}
                  placeholder="xxxxxxx"
                  isRequired
                  errorMessage={codeErr}
                  isInvalid={codeErr.length == 0 ? false : true}
                  onChange={e => setCode(e.target.value)}
                  radius="sm"
                  variant="bordered"
                />
                <Button
                  onClick={resetHandler}
                  className="bg-primaryOrange text-white"
                >
                  Check Code
                </Button>
              </>
            )}
          </div>
          <div className="flex mt-6 justify-between align-middle text-[12px]">
            <Link color="foreground" size="sm" href="/signup">
              Don’t have an account? Sign Up
            </Link>
          </div>
        </div>
        <div className="hidden  lg:flex">
          <img src={SideRight} />
        </div>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
        <ModalContent>
          {onClose => (
            <>
              {codeSuccess == false ? (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Email Reset Code Sent Successfully
                  </ModalHeader>

                  <ModalFooter>
                    <Button color="danger" variant="flat" onPress={onClose}>
                      Close
                    </Button>
                    <Button
                      color="success"
                      onPress={onClose}
                      className=" text-white"
                    >
                      Enter Code
                    </Button>
                  </ModalFooter>
                </>
              ) : (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Reset Password
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      label="Password"
                      variant="bordered"
                      labelPlacement={"outside"}
                      errorMessage={passwordErr}
                      isInvalid={passwordErr.length == 0 ? false : true}
                      onChange={e => setPassword(e.target.value)}
                      isRequired
                      radius="sm"
                      placeholder="Enter your password"
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isVisible ? (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isVisible ? "text" : "password"}
                    />
                    <Input
                      label="Confirm Password"
                      variant="bordered"
                      labelPlacement={"outside"}
                      // errorMessage={passwordErr}
                      // isInvalid={emailErr.length == 0 ? false : true}
                      onChange={e => setPasswordConfirmation(e.target.value)}
                      isRequired
                      radius="sm"
                      placeholder="Enter your password"
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isVisible ? (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isVisible ? "text" : "password"}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" variant="flat" onPress={onClose}>
                      Close
                    </Button>
                    <Button color="primary" onPress={newPasswordHandler}>
                      Reset
                    </Button>
                  </ModalFooter>
                </>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
      {/* <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex flex-col gap-1">Log in</ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  endContent={
                    <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="bordered"
                />
                <Input
                  endContent={
                    <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="bordered"
                />
                <div className="flex py-2 px-1 justify-between">
                  <Checkbox
                    classNames={{
                      label: "text-small",
                    }}
                  >
                    Remember me
                  </Checkbox>
                  <Link color="primary" href="#" size="sm">
                    Forgot password?
                  </Link>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={onClose}>
                  Sign in
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal> */}
    </div>
  );
}
