import React from "react";

import SideRight from "../../resources/images/side right (1).png";
import GoogleIcon from "../../resources/images/search.svg";
import FaceBookIcon from "../../resources/images/facebook.svg";
import { Button, Divider, Input, Link } from "@nextui-org/react";
import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";
import { Textarea } from "@nextui-org/react";

import axios from "../../main/axios/index";
import AuthReducer from "library/common/reducers/AuthReducer";
import { getAuth, setAuth } from "library/common/actions/AuthActions";
import { saveToStorage } from "library/utilities/Storage";
import store from "main/store";
import { Select, SelectItem } from "@nextui-org/react";

export default function ConctactUs({ isLoggedIn }) {
  const [isVisible, setIsVisible] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameErr, setNameErr] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [subjectErr, setSubjectErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const toggleVisibility = () => setIsVisible(!isVisible);

  const loginHandler = () => {
    var data = {
      email: email,
      name: name,
      subject: subject,
      message: message,
    };

    axios
      .post("/contactus", data)
      .then(response => {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        alert("Message Send Successfully");
        window.location.href = "/";
      })
      .catch(err => {
        if (err.response.data.errors) {
          if (err.response.data.errors.name) {
            setNameErr(err.response.data.errors.name);
          } else {
            setNameErr("");
          }
          if (err.response.data.errors.email) {
            setEmailErr(err.response.data.errors.email);
          } else {
            setEmailErr("");
          }
          if (err.response.data.errors.subject) {
            setSubjectErr(err.response.data.errors.subject);
          } else {
            setSubjectErr("");
          }
          if (err.response.data.errors.message) {
            setMessageErr(err.response.data.errors.message);
          } else {
            setMessageErr("");
          }
        }
      });
  };

  return (
    <div className=" flex justify-center align-middle">
      <div className="flex xl:justify-between lg:justify-between md:justify-center sm:justify-center align-middle w-[80%]">
        <div className="lg:w-[40%] w-full">
          <div className="">
            <h1 className="  text-[28px] font-[600]">Get In Touch</h1>
            <p>We are here for you! How can we help?</p>
          </div>

          <Divider className="my-4" />
          <div className="flex w-full flex-col flex-wrap md:flex-nowrap gap-4">
            <Input
              type="text"
              label="Name"
              value={name}
              labelPlacement={"outside"}
              placeholder="Full Name"
              isRequired
              errorMessage={nameErr}
              isInvalid={nameErr.length == 0 ? false : true}
              onChange={e => setName(e.target.value)}
              radius="sm"
              variant="bordered"
            />
            <Input
              type="email"
              value={email}
              label="Email"
              labelPlacement={"outside"}
              placeholder="Example@gmail.com"
              isRequired
              errorMessage={emailErr}
              isInvalid={emailErr.length == 0 ? false : true}
              onChange={e => setEmail(e.target.value)}
              radius="sm"
              variant="bordered"
            />
            <Select
              isRequired
              label="Subject"
              variant="bordered"
              value={subject}
              placeholder="Select Subject"
              defaultSelectedKeys={["Help Center"]}
              errorMessage={subjectErr}
              isInvalid={subjectErr.length == 0 ? false : true}
              onChange={e => setSubject(e.target.value)}
              labelPlacement="outside"
            >
              <SelectItem key={"Help Center"} value={"Help Center"}>
                {"Help Center"}
              </SelectItem>
              <SelectItem value={"Technical Issue"} key={"Technical Issue"}>
                {"Technical Issue"}
              </SelectItem>
              <SelectItem value={"Other"} key={"Other"}>
                {"Other"}
              </SelectItem>
            </Select>
            <Textarea
              label="Message"
              isRequired
              value={message}
              labelPlacement="outside"
              errorMessage={messageErr}
              isInvalid={messageErr.length == 0 ? false : true}
              onChange={e => setMessage(e.target.value)}
              placeholder="Enter your Message"
              variant="bordered"
            />
            <Button
              onClick={loginHandler}
              className="bg-primaryOrange text-white"
            >
              Send Message
            </Button>
          </div>
        </div>
        <div className="hidden  lg:flex">
          <img src={SideRight} />
        </div>
      </div>
    </div>
  );
}
