import React from "react";
import { Card, CardBody, Image } from "@nextui-org/react";
import Rate1 from "../resources/images/rate1.svg";
import Rate2 from "../resources/images/rate2.svg";
import Rate3 from "../resources/images/rate3.svg";
import Rate4 from "../resources/images/rate4.svg";
import Rate5 from "../resources/images/rate5.svg";
import ReactStars from "react-stars";

export default function ReviewCard({ title, rate, review }) {
  return (
    <Card className="bg-secondaryGray shadow-none lg:w-[29%] md:w-[100%] sm:w-[100%]">
      <CardBody>
        <div className="flex justify-start items-center gap-3 p-0">
          <div className="w-1/3">
            <Image
              className=" object-cover"
              width={48}
              height={48}
              src={
                rate == 1
                  ? Rate1
                  : rate == 2
                  ? Rate2
                  : rate == 3
                  ? Rate3
                  : rate == 4
                  ? Rate4
                  : Rate5
              }
            />
          </div>
          <div className="w-2/3">
            <h1 className="text-large font-medium mt-2">{title}</h1>
            <div>
              <ReactStars
                size={24}
                color2={"#ffd700"}
                value={rate}
                edit={false}
              />
            </div>
            <p className="text-small max-w-[1000px] text-foreground/80  ">
              {review}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
